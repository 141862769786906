@use "../../../Assets/Scss/media-queries.scss" as *;

.main {
  padding: 2% 10%;
  background-color: #000;

  @include lt-xxl {
    padding: 2% 8%;
  }

  @include lt-md {
    padding: 10vw 6%;
  }

  h1 {
    color: #fff;

    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
    width: fit-content;
    margin: auto;

    position: relative;

    @include lt-xxl {
      font-size: 55px;
    }

    @include lt-md {
      font-size: 32px;
    }

    img {
      position: absolute;
      top: -7px;
      right: -27px;
      width: 30px;

      @include lt-md {
        top: -30%;
        right: -5%;
      }
    }
  }

  h4 {
    color: rgba(255, 255, 255, 0.62);
    margin-top: 0.5%;
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5;

    @include lt-md {
      font-size: 16px;
    }
  }
}

.nutretions {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin-top: 2%;
  width: 100%;
  flex-wrap: wrap;

  @include lt-md {
    flex-wrap: wrap;
  }
}

.nut {
  margin: 10px 0.5%;
}

.btn {
  margin-top: 2%;
  button {
    width: 230px !important;
  }
}
