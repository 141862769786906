@use "../../../Assets/Scss/media-queries.scss" as *;

.checkboxes {
  width: 100%;
  margin-bottom: 10px;
}

.checkboxes h3 {
  font-size: 20px;
  font-weight: 500;
}

.items {
  height: fit-content;
  border-radius: 10px;
  width: 100%;
  overflow-y: auto;
  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.items::-webkit-scrollbar {
  width: 0px;
}

.item_select_text {
  font-size: 17px;
  font-weight: 400;
  color: #000000;
  width: fit-content;
  user-select: none;
}

.item_select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 6px 0px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.07);
  padding: 7px 10px;
  border-radius: 5px;
  margin-right: 10px;
  transition: all 0.3s;
}

.checked {
  background-color: var(--main);
  opacity: 0.9;

  .item_select_text {
    color: white;
  }
}

.invalid {
  color: var(--main) !important;
}
