@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 50px 10% 100px 10%;

  @include lt-md {
    padding: 20px 5%;
  }

  .h1 {
    margin: 0px;
    width: fit-content;
    margin: auto;
  }

  .main_descrp {
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.7;

    @include lt-md {
      font-size: 16px;
      margin-top: 20px;
      text-align: justify;
    }
  }
}

.search_input {
  width: 40%;
  margin: 50px auto;
  padding: 0px 1%;

  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #000000;
  border-radius: 500px;
  overflow: hidden;
  transition: all 0.2s;

  @include lt-md {
    width: 100%;
    padding: 0px 4%;
    margin: 20px auto;
  }

  &:hover {
    width: 45%;
    @include lt-md {
      width: 100%;
    }
  }

  input {
    width: 90%;
    padding: 20px 10px 20px 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.7;
    outline: none;
    border: none;

    @include lt-md {
      padding: 10px 10px 10px 0px;
    }
  }

  img {
    cursor: pointer;
    transition: all 0.2s;

    @include lt-md {
      height: 25px;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
}

.blogs {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.blog {
  cursor: pointer;
  width: 31%;
  margin: 2% 1%;

  display: flex;
  flex-direction: column;

  @include lt-md {
    width: 100%;
  }

  &:hover {
    .img img {
      transform: scale(1.1);

      @include lt-md {
        transform: scale(1);
      }
    }
  }

  .img {
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: 50% 50%;
      transition: all 0.2s;
    }
  }

  .content {
    // margin: 3% 0px;
    background-color: #e7e7e7;
    padding: 4% 4% 1% 4%;
    border-radius: 0px 0px 10px 10px;
    flex-grow: 1;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.7;

      @include lt-md {
        font-size: 15px;
      }
    }

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
      margin: 2% 0px 1% 0px;

      @include lt-md {
        font-size: 20px;
      }
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.7;
      margin: 5% 0px;
      text-align: justify;

      @include lt-md {
        font-size: 15px;
      }
    }

    .blog_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .more {
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          span {
            color: var(--main);
          }

          img {
            filter: brightness(0) saturate(100%) invert(20%) sepia(100%)
              saturate(5514%) hue-rotate(347deg) brightness(84%) contrast(119%);
          }
        }

        span {
          color: #000;
          margin: 0px 5px 0px 0px;
          font-size: 18px;
          font-weight: 700;
          line-height: 1;
          cursor: pointer;
          transition: all 0.2s;
        }

        img {
          height: 18px;
          transition: all 0.2s;
        }
      }
    }
  }
}
