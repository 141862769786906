@use "../../../Assets/Scss/media-queries.scss" as *;

.filter_btn {
  height: 50px;
  width: 32%;
  border-radius: 500px;
  color: #000000;
  border: 1px solid #000000;
  font-size: 17px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s;

  @include lt-md {
    width: 32%;
    height: 40px;
    margin: 10px 0px;
    border-radius: 500px;
    font-size: 15px;
    position: inherit;
  }

  &:hover {
    border: 1px solid var(--main);
    outline: 1px solid var(--main);
    color: var(--main);

    @include lt-md {
      font-size: 15px;
      outline: none;
    }
  }
}

.actualButton {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown {
  position: absolute;
  display: block;
  height: fit-content;
  width: fit-content;
  top: 50px;
  z-index: 50;
  transform: translateY(5px);
  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-md {
    width: 100vw;
    height: 100vh;
    top: 0px;
    right: 0px;
    background-color: #00000099;
    z-index: 999999;
    position: fixed;
  }
}

.items {
  height: fit-content;
  min-width: 350px;
  max-height: 350px;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 0px 5px;
  overflow-y: auto;
  position: relative;

  @include lt-md {
    max-height: 95vh;
    position: relative;
    height: fit-content;
    width: 95vw;
  }
}

.items::-webkit-scrollbar {
  width: 0px;
}

.item_select_text {
  font-size: 17px;
  font-weight: 500;
  color: #000000;
  margin-right: 10px;
  width: fit-content;
}

.item_select {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.07);
  padding: 5px;
  border-radius: 5px;
}

.item_select_box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;

  height: 30px;
  aspect-ratio: 1;
  border-radius: 3px;
  margin: 0px 10px 0px 0px;

  @include lt-md {
    height: 30px;
  }

  img {
    width: 70%;
  }
}

.checked {
  background-color: var(--main);

  .item_select_text {
    color: white;
  }
}

.closeContainer {
  display: flex;
  justify-content: flex-end;
  background-color: var(--main);
  width: 32px;
  aspect-ratio: 1;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: white;
  font-size: 26px;

  position: fixed;
  right: 2.3vw;

  display: none;
  @include lt-md {
    display: flex;
  }
}
