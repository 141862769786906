@use "../../../Assets/Scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 50px 10% 50px 10%;

  @include lt-xxl {
    padding: 50px 8%;
  }

  @include lt-md {
    padding: 10vw 6%;
  }
}

.head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include lt-md {
    flex-direction: column;
  }

  .txts {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      color: #000;
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;

      @include lt-xxl {
        font-size: 35px;
      }
    }

    span {
      color: rgba(0, 0, 0, 0.62);

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.7;
    }
  }

  .figure_line {
    flex-grow: 1;

    height: 2px;
    padding: 0px 2%;

    display: flex;
    align-items: center;
    justify-content: center;

    @include lt-md {
      display: none;
    }

    .line {
      width: 100%;
      height: 2px;
      background-color: black;
    }

    .square {
      height: 10px;
      width: 10px;
      background-color: black;
      transform: rotate(45deg);
    }
  }

  .btn {
    @include lt-md {
      display: none;
    }
  }
}

.blogs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 5%;

  @include lt-md {
    flex-direction: column;
  }

  .main_blog,
  .blog {
    aspect-ratio: 805 / 472;
    position: relative;

    overflow: hidden;
    border-radius: 20px;

    @include lt-md {
      aspect-ratio: 300 / 300;
      border-radius: 10px;
    }

    &:hover {
      .img {
        transform: scale(1.1);
      }
    }

    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 3;
      padding: 2%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include lt-md {
        height: 100%;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
      }
    }

    .go_to {
      aspect-ratio: 1/1;
      border-radius: 10%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      @include lt-md {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      &:hover {
        img {
          width: 40%;
        }
      }

      img {
        width: 30%;
        transition: all 0.1s;
      }
    }

    .img {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center center;

      transition: all 0.2s;
    }

    .filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .main_blog {
    width: 75%;

    @include lt-md {
      width: 100%;
    }

    .content {
      .infos {
        width: 100%;

        @include lt-md {
          width: 100%;
        }

        h1 {
          color: var(--white);

          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 1.7;

          margin: 0px 0px 1% 0px;

          display: flex;
          align-items: center;
          justify-content: space-between;

          @include lt-xxl {
            font-size: 20px;
          }

          @include lt-md {
            flex-direction: column;
            align-items: flex-start;
            font-size: 16px;
            margin: 0px 0px 0% 0px;
          }

          span {
            // color: rgba(255, 255, 255, 0.65);
            color: var(--main);

            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.7;

            @include lt-xxl {
              font-size: 14px;
            }
            @include lt-md {
              font-size: 13px;
            }
          }
        }

        p {
          color: #fff;

          font-size: 16px;
          font-style: normal;
          text-align: justify;
          font-weight: 400;
          line-height: 1.7;

          @include lt-xxl {
            font-size: 14px;
          }

          @include lt-md {
            font-size: 13px;
          }
        }
      }

      .go_to {
        height: 100px;
        @include lt-xxl {
          height: 80px;
        }

        @include lt-md {
          height: 60px;
        }
      }
    }
  }

  .sub_blogs {
    width: 24%;

    @include lt-md {
      width: 100%;
    }

    .blog {
      width: 100%;
      margin: 3.8% 0px;

      @include lt-md {
        aspect-ratio: 300 / 120 !important;
      }

      .content {
        padding: 4%;

        .infos {
          h1 {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.2;

            @include lt-xxl {
              font-size: 14px;
            }
          }
        }

        .go_to {
          height: 50px;

          @include lt-xxl {
            height: 40px;
          }

          @include lt-md {
            height: 35px;
            right: 3%;
          }
        }
      }
    }
  }
}
