@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  width: 180px;
  height: 55px;
  flex-shrink: 0;

  border-radius: 10px;

  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.05);
  }
}

.big {
  font-size: 20px !important;
  width: 200px !important;
  height: 60px !important;
}

.verry-big {
  font-size: 23px !important;
  width: 250px !important;
  height: 70px !important;
}

.centerer,
.lefter,
.righter {
  width: 100%;
  display: flex;
  align-items: center;
}

.centerer {
  justify-content: center;
}

.lefter {
  justify-content: left;
}

.righter {
  justify-content: right;
}

// ####################################" COLORS "####################################

.main-color {
  background: var(--main);
  color: #fff;
}

.black-color {
  background: var(--black);
  color: #fff;
}

.green-color {
  background-color: var(--green);
  color: white;
}

.white-color {
  background-color: var(--white);
  color: #000;
}
