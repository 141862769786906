@use "./Assets/Scss/media-queries.scss" as *;

//############################ Colors #######################

:root {
  --page-background: #fff;
  --white: #fff;
  --main: #e70014;
  --green: #008000;
  --black: #000000;
}

//############################ SCROLL #######################

// ::-webkit-scrollbar {
//   width: 0px;

//   @include lt-xxl {
//     width: 9px;
//   }

//   @include lt-xl {
//     width: 8px;
//   }

//   @include lt-xxl {
//     width: 7px;
//   }

//   @include lt-md {
//     width: 0px;
//   }
// }

p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection,
span::selection,
strong::selection {
  background: var(--main);
  color: white;
}

::-webkit-scrollbar {
  width: 10px;

  @include lt-xxl {
    width: 9px;
  }

  @include lt-xl {
    width: 8px;
  }

  @include lt-xxl {
    width: 7px;
  }

  @include lt-md {
    width: 0px;
  }
}

::-webkit-scrollbar-thumb {
  background: black;
  border: 1px solid rgba(217, 217, 217, 0.46);
}

::-webkit-scrollbar-track {
  background-color: rgba(217, 217, 217, 0.46);
}

//############################ FONTS #######################

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Poppin";
  src: url("./Assets/Fonts/Poppins-Black.ttf");
  font-weight: 900;
}

//############################ COMMON #######################

body {
  margin: 0;
  padding: 0;
  background-color: var(--page-background);
}

* {
  box-sizing: border-box;
  font-family: Montserrat;
  margin: 0;
  padding: 0;
  font-family: "Poppin";
  // user-select: none;
}

.red {
  color: var(--main) !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.no-scroll-bar {
  scrollbar-width: none;
}

// GOOGLE TRANSLATE

.skiptranslate {
  display: none !important;
}

#goog-gt-tt {
  display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}

body {
  margin: 0;
  padding: 0;
  top: 0px !important;
  // min-width: 1500px;
}

.only-show-notion {
  * {
    padding-inline: 0px !important;
  }
}
