@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 50px 10% 100px 10%;

  @include lt-xxl {
    padding: 50px 7%;
  }

  @include lt-md {
    padding: 20px 5%;
  }

  .h1 {
    margin: 0px;
    width: fit-content;
    margin: auto;
  }

  .main_descrp {
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.7;

    @include lt-md {
      font-size: 16px;
      margin-top: 20px;
      text-align: justify;
    }
  }
}

.filters {
  margin: 2% auto;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include lt-md {
    margin: 4% auto;
    width: 100%;
    padding: 0px 0%;
  }
}

.trainers {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5%;
  width: 100%;

  .trainer {
    width: 24%;
    margin: 0px 0.5% 2% 0.5%;

    @include lt-xl {
      width: 31%;
      margin: 0px 1.16% 2% 1.16%;
    }

    @include lt-lg {
      width: 48%;
      margin: 0px 1% 2% 1%;
    }

    @include lt-md {
      width: 100%;
    }
  }
}
