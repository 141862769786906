@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  padding: 100px 10%;

  @include lt-xxl {
    padding: 50px 8%;
  }

  @include lt-md {
    padding: 20px 6%;
  }
}

.h1 {
  text-align: center;
  margin: 0px 0px 100px 0px;

  @include lt-md {
    margin: 0px 0px 20px 0px;
  }
}

.content {
  width: 100%;

  img {
    width: 70%;
    display: block;
    margin: 2% auto;

    @include lt-md {
      width: 100%;
    }
  }

  h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.7;

    @include lt-md {
      font-size: 26px;
      line-height: 1.4;
    }
  }

  h2 {
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.7;

    @include lt-md {
      font-size: 24px;
      line-height: 1.4;
    }
  }

  h3 {
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.7;
    margin: 30px 0px 0px 0px;

    @include lt-md {
      font-size: 22px;
      line-height: 1.4;
    }
  }
  h4 {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.7;

    @include lt-md {
      font-size: 20px;
      line-height: 1.4;
    }
  }

  p,
  li {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 2;
    text-align: justify;
    margin: 0.5% 0px;

    @include lt-md {
      font-size: 18px;
      text-align: justify;
    }
  }
}
