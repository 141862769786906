@use "../../../Assets/Scss/media-queries.scss" as *;

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .bg_img {
    width: 100%;
    height: 105vh;
    object-fit: cover;
    object-position: 0% 0%;

    @include lt-md {
      height: 110vh;
      object-position: 50% 50%;
    }
  }

  .content {
    margin-top: 5%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1 {
      color: #fff;

      text-align: center;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2;

      @include lt-xxl {
        font-size: 50px;
      }

      @include lt-md {
        margin-top: 120px;
        font-size: 40px;
      }
    }

    h2 {
      color: #fff;
      // font-size: 40px;
      font-size: 23px;
      font-style: normal;
      margin-top: 10px;
      // font-weight: 600;
      line-height: 178%;
      text-align: center;

      @include lt-xxl {
        // font-size: 35px;
        font-size: 20px;
      }

      @include lt-md {
        // font-size: 25px;
        font-size: 16px;
        text-align: center;
        line-height: 1.5;
        margin-top: 30px;
        padding: 0px 10%;
      }
    }

    p {
      margin-top: 2%;
      text-align: center;
      color: #fff;
      font-size: 23px;
      font-style: normal;
      font-weight: 400;

      @include lt-xxl {
        font-size: 20px;
      }

      @include lt-md {
        font-size: 16px;
        margin-top: 40px;
        padding: 0px 5%;
        text-align-last: center;
        br {
          display: none;
        }
      }
    }

    .btns {
      @include lt-md {
        flex-direction: column !important;
        margin: 30px 0px 0px 0px;
      }

      button {
        margin: 50px 20px 0px 20px;

        width: 250px !important;
        height: 65px !important;

        font-size: 20px !important;

        @include lt-xxl {
          font-size: 20px !important;
          height: 60px !important;
          width: 230px !important;
        }

        @include lt-md {
          margin: 30px 20px 0px 20px;
          height: 55px !important;
          width: 200px !important;
          font-size: 18px !important;
        }
      }
    }
  }
}
