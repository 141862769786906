@use "../../Assets/Scss/media-queries.scss" as *;

.h1 {
  color: #000;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;

  @include lt-xxl {
    font-size: 50px;
  }

  @include lt-md {
    font-size: 40px;
    line-height: 1.2;
  }
}

.head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: #000;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.2;
  }

  .figure_line {
    flex-grow: 1;

    height: 2px;
    padding: 0px 2%;

    display: flex;
    align-items: center;
    justify-content: center;

    .line {
      width: 100%;
      height: 2px;
      background-color: black;
    }

    .square {
      height: 10px;
      width: 10px;
      background-color: black;
      transform: rotate(45deg);
    }
  }
}

.is_white {
  h1 {
    color: #fff;
  }

  .figure_line {
    .line {
      background-color: #fff;
    }

    .square {
      background-color: #fff;
    }
  }
}
