.container {
  max-width: 150px;
  // border: solid 1px #ffffff55;
  border-radius: 5px;
  margin-top: 10px;
  padding: 0px;

  * {
    line-height: 1.5 !important;
    font-size: 18px !important;
  }

  * svg {
    height: 30px !important;
    width: 30px !important;
  }

  * button {
    padding: 3px 8px 3px 5px !important;
  }
}

.selectButtonNoChevron {
  &:after,
  &[aria-expanded="true"]:after {
    content: " ";
    width: 0 !important;
    height: 0 !important;
    display: inline-block;
    margin-left: 0px !important;
  }

  &:after {
    border-left: 0px solid transparent !important;
    border-right: 0px solid transparent !important;
    border-bottom: 0 !important;
  }

  &[aria-expanded="true"]:after {
    border-top: 0;
    border-left: 0px solid transparent !important;
    border-right: 0px solid transparent !important;
  }
}

.langSelect {
  * {
    color: white;
    border: none !important;
    font-size: 26px;
  }

  border: none !important;

  ul {
    * {
      color: black;
    }
  }
}

.isUp {
  ul {
    top: -145px !important;
    * {
      margin-top: 0px !important;
    }
  }
}
