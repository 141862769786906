.main {
}

.navnormal {
  width: 100%;
  position: fixed;
  top: 0px;
  height: 120px;
  background-image: url("../../Assets/Images/filters/filter_navbar.png");
  background-position: 50% 50%;
  background-size: 100% 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5%;
  z-index: 999;
  transition: all 0.2s;

  .actual_logo {
    height: 30px;
  }

  .burger {
    color: white;
    font-size: 30px;
  }
}

.not_in_home,
.is_scrolled {
  .navnormal {
    height: 80px;
    background-color: black;
  }
}

.navbar {
  width: 100%;
  height: 100vh;
  background-color: black;
  position: fixed;
  z-index: 998;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 6% 5%;
  bottom: 100%;
  transition: all 1s cubic-bezier(1, 0, 0, 1);
}

.open {
  bottom: 0%;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  opacity: 0;

  img {
    height: 30px;
  }

  .close {
    color: white;
    font-size: 30px;
  }
}

.mid {
  padding: 30px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  .link__item,
  div .link__item {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    display: block;
    text-align: center;
    margin: 7% 0px;

    // margin: 0px;
    cursor: pointer;
    transition: all 0.2s;
  }
}

.collapser {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.collapser_part {
  height: 0px;
  overflow: hidden;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 0px 20px;
  transition: all 0.2s;
  margin-top: -5%;

  * {
    color: black !important;
  }
}

.collapser_part_open {
  height: 100px;

  * {
    margin: 10px 0px !important;
  }
}

.nutri {
  // color: var(--main) !important;
  color: white !important;
  padding: 7px 10px;
  margin: 8px 0px !important;
  border-radius: 6px;
  background-color: var(--main);
  width: 100%;
}

.coach {
  // color: var(--green) !important;
  color: white !important;
  padding: 7px 10px;
  margin: 8px 0px !important;
  border-radius: 6px;
  background-color: var(--green);
  width: 100%;
}

.bot {
  margin-bottom: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .btn_register {
    font-size: 24px;
    font-weight: 600;
    color: var(--main);
    cursor: pointer;
    transition: all 0.2s;
  }

  .btn {
    font-size: 20px;
    padding: 0px 30px;
    width: fit-content;
  }
}

.auth_part {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20%;
}
