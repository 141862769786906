@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  width: 100%;
}

.header {
  width: 100%;
  aspect-ratio: 10/1.2;
  position: relative;
  border-bottom: 7px solid #000;

  @include lt-md {
    aspect-ratio: 10/4;
  }

  .cover {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .filter {
    position: absolute;
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.2);
    top: 0px;
    right: 0px;
    z-index: 2;
  }

  .content {
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    z-index: 2;
    padding: 50px 10% 0px 10%;

    @include lt-md {
      padding: 20px 6% 0px 6%;
    }
  }
}

.header2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10% 0px 10%;

  transform: translateY(-25%);

  @include lt-md {
    flex-direction: column;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @include lt-md {
      flex-direction: column;
    }

    .avatar_img {
      border: 7px solid #fff;
      background-color: #000;
      border-radius: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.05);
      }

      img {
        width: 230px;
        aspect-ratio: 1/1;
        border-radius: 500px;
        object-fit: cover;
        object-position: 50% 50%;

        @include lt-xxl {
          width: 200px;
        }

        @include lt-md {
          width: 180px;
        }
      }
    }

    .name {
      margin: 0px 0px 0px 2%;

      @include lt-md {
        margin-bottom: 20px;
      }

      @include lt-md {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      h3 {
        color: #000;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;

        @include lt-xxl {
          font-size: 30px;
        }
      }

      span {
        display: block;
        margin: 2% 0px 0px 0px;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2;
      }
    }
  }
  .btn {
    width: fit-content !important;
    height: fit-content !important;
    padding: 20px 40px;
    border-radius: 15px;

    @include lt-xxl {
      padding: 15px 30px;
      border-radius: 10px;
      font-size: 16px;
    }
  }
}

.body {
  width: 100%;
  padding: 0px 0px 20px 0px;
}

.about_and_certifs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 10%;

  @include lt-md {
    flex-direction: column;
    padding: 0px 6%;
  }
}

.about {
  width: 48%;

  @include lt-md {
    margin-top: -50px;
    width: 100%;
  }

  h1 {
    color: #000;

    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.3;

    @include lt-xxl {
      font-size: 50px;
    }

    @include lt-md {
      font-size: 35px;
    }
  }

  p {
    color: #000;

    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8;
    text-align: justify;
    margin: 2% 0px;

    @include lt-xxl {
      font-size: 18px;
    }
  }
}

.certif_container {
  width: 46%;
  @include lt-md {
    width: 100%;
  }
}

.certifs {
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0px 0px 1% 0px;
}

.certif {
  // width: 49%;
  width: fit-content;
  margin: 1% 0% 1% 0px;
  padding: 3% 2.5%;
  border-radius: 15px;
  background-color: #e7e7e7;
  cursor: pointer;

  @include lt-xxl {
    border-radius: 10px;
  }

  @include lt-md {
    width: 100%;
  }

  * {
    transition: all 0.2s;
  }

  h1 {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.7;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include lt-xxl {
      font-size: 20px;
    }

    img {
      height: 18px;
      margin-right: 7px;

      @include lt-xxl {
        height: 16px;
        margin-right: 5px;
      }
    }
  }

  p {
    color: #000;
    font-size: 16px;

    @include lt-xxl {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &:hover {
    h1 {
      color: var(--main);

      img {
        filter: brightness(0) saturate(100%) invert(20%) sepia(100%)
          saturate(5514%) hue-rotate(347deg) brightness(84%) contrast(119%);
      }
    }

    p {
      color: var(--main);
    }
  }
}

.education_and_certifs {
  margin: 50px 0px;
  background-color: black;
  padding: 5% 10%;

  @include lt-md {
    margin: 30px 0px;
    padding: 5% 6%;
  }

  .textWithLine {
    @include lt-md {
      text-align: center;
      figure {
        display: none;
      }
    }
  }

  .items {
    width: 100%;
    margin: 3% 0px 1% 0px;
    align-items: flex-start;

    .item {
      width: 25%;

      @include lt-md {
        width: 100%;
        margin: 20px 0px;
      }

      img {
        width: 50px;
        margin: 0px 2% 0px 0px;
        transform: translateX(-3px);
      }

      span {
        color: #fff;
        font-size: 22px;
        font-weight: 600;
      }

      p {
        margin: 3% 0px 0px 0px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        text-align: justify;
      }
    }
  }
}

.servs {
  margin: 4% 0px;
}

.gallery {
  margin: 50px 0px 0px 0px;
  padding: 0px 10%;

  @include lt-xxl {
    padding: 0px 8%;
  }

  @include lt-md {
    padding: 0px 4%;
    margin: 30px 0px 0px 0px;
  }

  .textWithLine {
    @include lt-md {
      text-align: center;
      figure {
        display: none;
      }
    }
  }

  .images {
    width: 100%;
    margin: 50px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include lt-md {
      margin: 15px 0px 0px 0px;
    }

    .img {
      width: 23%;
      margin: 1%;
      aspect-ratio: 16/18;
      overflow: hidden;
      border-radius: 15px;
      cursor: pointer;

      @include lt-xxl {
        aspect-ratio: 16/16;
      }

      @include lt-md {
        width: 48%;
        border-radius: 8px;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: 50% 50%;
        transition: all 0.2s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.video_section {
  width: 100%;
  margin: 50px 0px;

  .video {
    margin: 30px 0px 0px 0px;
    aspect-ratio: 16/9;
  }
}
