@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  width: 100%;
  padding: 100px 10%;

  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include lt-md {
    padding: 20px 5%;
    flex-direction: column;
  }
}

.left {
  width: 65%;
  padding: 4% 6%;

  border: solid 4px #000;
  border-radius: 30px;

  @include lt-md {
    width: 100%;
    border: solid 0px #000;
    border-radius: 10px;
    padding: 4% 0%;
  }

  h1 {
    color: #000;

    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;

    @include lt-md {
      font-size: 30px;
      br {
        display: none;
      }
    }
  }
}

.input {
  width: 100%;
  margin: 5% 0px;
  padding: 20px 0px 20px 0px;
  border-bottom: solid 1px #00000099;

  display: flex;
  align-items: flex-start;

  @include lt-md {
    padding: 10px 0px 8px 0px;
    margin: 30px 0px 10px 0px;
  }

  img {
    width: 23px;
    height: 23px;
    margin-right: 10px;
    transform: translateY(2px);
  }

  input,
  textarea {
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    flex-grow: 1;
    resize: none;
  }
}

.btn {
  @include lt-md {
    font-size: 16px;
    width: 120px;
    height: 50px;
    border-radius: 8px;
    display: block;
    margin: 0px 0px 0px auto;
  }
}

.right {
  width: 35%;
  position: relative;

  @include lt-md {
    padding: 20px 0px;
    width: 100%;
  }

  .girl_img {
    width: 100%;
    position: absolute;
    bottom: 3%;
    left: -10%;

    @include lt-md {
      position: relative;
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .arrow_line {
    position: absolute;
    top: 2%;
    right: 10%;
    height: 260px;
    z-index: 1;

    @include lt-md {
      display: none;
    }
  }
}

.links_card {
  border-radius: 10px;
  position: absolute;
  left: -30%;
  top: 50px;
  background: rgba(255, 255, 255, 0.13);
  padding: 20px 30px;
  z-index: 2;

  box-shadow: 0px 3.29305px 89.73556px 0px rgba(0, 0, 0, 0.09);
  backdrop-filter: blur(20px);

  @include lt-md {
    left: 0%;
    top: 0px;
    width: 100%;
    position: relative;
    backdrop-filter: blur(0px);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
  }

  .link_info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 15px 0px;

    img {
      width: 25px;
      margin: 0px 8px 0px 0px;
    }
    span {
      font-size: 20px;
    }
  }
}
