@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  background-image: url("../../Assets/Images/filters/filter_navbar.png");
  background-position: 50% 50%;
  background-size: 100% 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 99;
  padding: 70px 100px;
  transition: all 0.2s;

  @include lt-xxl {
    padding: 40px 100px !important;
  }

  .logo {
    width: 250px;
    cursor: pointer;
    transition: all 0.2s;

    @include lt-xxl {
      width: 150px !important;
    }

    .actual_logo {
      width: 100%;
      transition: all 0.2s;
    }

    .beta_logo {
      width: 105px;

      @include lt-xxl {
        width: 70px !important;
      }
    }
  }

  .content {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: all 0.2s;

    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s;

      .link__item {
        color: #fff;
        font-size: 24px;
        font-weight: 400;
        position: relative;

        margin: 0px 25px;
        cursor: pointer;
        transition: all 0.2s;

        @include lt-xxl {
          font-size: 18px !important;
          margin: 0px 20px !important;
        }

        &::after {
          content: "";
          display: block;
          width: 0px;
          height: 4px;
          margin: 5px auto 0px auto;
          margin-top: 5px;
          background-color: #fff;
          border-radius: 500px;
          transition: width 0.2s ease-in-out;

          @include lt-xxl {
            width: 0px !important;
            height: 4px !important;
          }
        }

        &:hover {
          &::after {
            width: 100%;
          }

          & .more {
            height: fit-content !important;
            display: block;
          }
        }
      }

      .store {
        transition: all 0.2s;
        &:hover {
          color: var(--main);
          &::after {
            background-color: var(--main);
          }
        }
      }
    }

    .external_links {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: all 0.2s;

      * {
        font-size: 30px;
        height: 25px;
        cursor: pointer;
        display: block;
        margin: 0px 10px;
      }
    }

    .signup {
      button {
        padding: 0px 30px;
        font-size: 20px;
        width: fit-content;
        transition: all 0.2s;

        @include lt-xxl {
          padding: 0px 20px;
          font-size: 16px;
          height: 45px;
        }
      }

      .btn_register {
        font-size: 20px;
        font-weight: 700;
        margin-right: 20px;
        color: var(--main);
        margin: 0px 50px 0px 0px;
        cursor: pointer;
        transition: all 0.2s;

        @include lt-xxl {
          font-size: 16px;
          margin: 0px 30px 0px 0px;
        }

        &:hover {
          transform: scale(1.05);
        }
      }

      .lang {
        margin: 0px 15px 0px 0px;
      }
    }
  }
}

.not_in_home {
  background-color: black;
  position: fixed;
  padding: 30px 150px 30px 150px;

  @include lt-xxl {
    padding: 30px 70px !important;
  }

  .logo {
    width: 250px;
  }

  .content {
    margin-top: 0px;

    .links {
      .link__item {
        font-size: 20px;

        margin: 0px 25px;

        &::after {
          width: 0px;
          height: 4px;
          margin-top: 4px;
        }
      }
    }
  }
}

.is_scrolled {
  padding: 30px 150px 30px 150px;
  // background-image: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

  background-color: rgba(0, 0, 0);

  // backdrop-filter: blur(10px);
  // background-color: rgba(0, 0, 0, 0.7);

  @include lt-xxl {
    padding: 30px 70px !important;
  }

  .logo {
    width: 250px;
  }

  .content {
    margin-top: 0px;

    .links {
      .link__item {
        font-size: 20px;

        margin: 0px 25px;

        &::after {
          width: 0px;
          height: 4px;
          margin-top: 4px;
        }
      }
    }
  }

  // .sub_link {
  //   font-size: 15px !important;
  // }
}

// Gap Spacer
.gap_spacer {
  height: 116px;

  @include lt-xxl {
    height: 100px;
  }

  @include lt-lg {
    height: 80px !important;
  }
}

.more {
  width: 120%;
  height: 0px;
  background-color: #fcfdfc;
  position: absolute;
  top: 90%;
  left: -10%;
  transition: all 0.2s;
  border-radius: 5px;
  overflow: hidden;
  padding: 8px 10px !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  display: none;

  .sub_link {
    font-size: 18px;
    color: black;
    padding: 8px 0px;
    display: block;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    transition: all 0.2s;
    margin-top: 5px;

    &:hover {
      background-color: var(--main);
      color: white;
    }
  }

  .hover_green {
    &:hover {
      background-color: var(--green);
      color: white;
    }
  }
}

.no_anim {
  &:hover {
    &::after {
      width: 0% !important;
    }
  }
}
