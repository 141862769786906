@use "../../../Assets/Scss/media-queries.scss" as *;

.input {
  border-radius: 12px;
  margin: 0 0 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.13);
  background: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  padding: 18px 20px;
  outline: none;
  position: relative; /* Required for pseudo-elements */
  color: #000;
  width: 100%;

  @include lt-xxl {
    padding: 12px 20px;
    font-size: 14px;
  }
}

.invalid {
  border-color: var(--main) !important;
  color: var(--main) !important;
}

.placeholder {
  position: absolute;
  color: rgba(0, 0, 0, 0.46);
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 18px 20px;
  font-size: 16px;

  @include lt-xxl {
    padding: 12px 20px;
    font-size: 14px;
    height: 51px;

    display: flex;
    align-items: center;
  }
}
