@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    transform: translateX(-10px);
  }

  img {
    height: 25px;

    @include lt-md {
      height: 18px;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;

    display: block;
    margin-left: 20px;

    @include lt-md {
      margin-left: 10px;
      font-size: 18px;
    }
  }
}

.white {
  * {
    color: #fff !important;
  }
}

.black {
  * {
    color: #000;
  }

  img {
    filter: brightness(0);
  }
}
