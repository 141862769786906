@use "../../../Assets/Scss/media-queries.scss" as *;

.trainer {
  cursor: pointer;
  width: 23%;
  transition: all 0.2s;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 1%;

  @include lt-xxl {
    width: 24%;
    margin: 10px 0.5%;
  }

  @include lt-xl {
    width: 30%;
    margin: 10px 0.5%;
  }

  @include lt-lg {
    width: 48%;
    margin: 10px 1%;
    display: flex;
    flex-direction: column;
  }

  @include lt-md {
    width: 500px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
  }

  @include lt-sm {
    width: 100%;
  }

  // &:hover {
  //   .img {
  //     height: 0px;
  //     position: absolute;

  //     @include lt-md {
  //       height: auto;
  //       position: relative;
  //     }
  //   }

  //   .info {
  //     height: 100%;
  //     margin-top: 0px;

  //     @include lt-md {
  //       height: fit-content;
  //       margin-top: 0px;
  //     }
  //   }

  //   .more {
  //     display: block;
  //   }

  //   .hide_on_more {
  //     display: none;
  //   }
  // }

  .img {
    width: 100%;
    aspect-ratio: 188/165;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
    transition: all 0.2s;

    @include lt-xxl {
      aspect-ratio: 188/150;
      border-radius: 10px 10px 0px 0px;
    }

    @include lt-md {
      border-radius: 10px 10px 0px 0px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      transition: all 0.2s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .more {
    display: none;
    @include lt-md {
      display: block;
    }
  }

  .hide_on_more {
    display: block;
    @include lt-md {
      display: none;
    }
  }

  .info {
    background-color: #141414;
    border-radius: 0px 0px 20px 20px;
    padding: 5% 5% 5% 5%;
    transition: all 0.2s;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    flex-grow: 1;

    @include lt-xxl {
      border-radius: 0px 0px 10px 10px;
    }

    @include lt-md {
      margin-top: 0px;
      border-radius: 0px 0px 10px 10px;
    }

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        color: #fff;
        font-size: 25px;
        font-weight: 700;
        line-height: 1.2;

        display: flex;

        @include lt-xxl {
          font-size: 24px;
        }
      }

      .go_to {
        width: 22%;
        aspect-ratio: 1/1;
        border-radius: 10%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        @include lt-xxl {
          width: 18%;
        }

        &:hover {
          img {
            width: 40%;
          }
        }

        img {
          width: 30%;
          transition: all 0.1s;
        }
      }
    }

    .body {
      margin-top: 5%;

      p {
        margin: 2% 0px;
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 2;

        @include lt-xxl {
          font-size: 16px;
        }

        span {
          font-weight: 600;
        }
      }
    }
  }
}

.white_card {
  .info {
    background-color: #f8f8f8;

    .head {
      h3 {
        color: #000;
      }

      .go_to {
        background-color: #000;
      }
    }

    .body {
      p {
        color: #000;
      }
    }
  }
}
