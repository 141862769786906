.rates {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    height: 22px;
    margin-right: 2px;
  }
}
