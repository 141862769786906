@use "../../../Assets/Scss/media-queries.scss" as *;

.main {
  background-color: #f8f8f8;
  width: 45vw;
  border-radius: 25px;
  padding: 3%;
  position: relative;
  flex-direction: column;

  @include lt-md {
    padding: 6% 6% 6% 6%;
    width: 92vw;
    border-radius: 10px;
    min-height: fit-content;
  }
}

.header {
  h1 {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0%;

    @include lt-md {
      font-size: 24px;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2%;

    @include lt-md {
      font-size: 16px;
    }
  }

  h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1%;
    @include lt-md {
      font-size: 16px;
    }
  }

  .desc {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.7;

    @include lt-md {
      font-size: 15px;
      text-align: justify;
      text-align-last: center;
      br {
        display: none;
      }
    }
  }
}

.body {
  width: 90%;
  flex-direction: column;
  margin: 4% auto 4% auto;

  @include lt-md {
    width: 100%;
    padding: 10% 0%;
  }

  input {
    width: 100%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    outline: none;
    border: none;

    margin: 0.5% 0px;

    @include lt-md {
      border-radius: 6px;
      padding: 14px;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

.footer {
  width: 100%;
  button {
    margin: 0px 0px 0px 2%;

    @include lt-md {
      font-size: 16px;
      width: 120px;
      height: 50px;
    }

    &:hover {
      transform: scale(1);
    }
  }
}

.check {
  width: 100%;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  cursor: pointer;

  padding: 14px;
  margin: 0.5% 0px;

  @include lt-md {
    border-radius: 8px;
    padding: 8px;
  }

  .checkbox {
    height: 40px;
    aspect-ratio: 1/1;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;

    @include lt-md {
      height: 25px;
      border-radius: 4px;
    }

    .centerItem {
      width: 60%;
      aspect-ratio: 1/1;
      border-radius: 6px;
    }
  }

  .radiobox {
    border-radius: 500px;
    .centerItem {
      width: 50%;
      aspect-ratio: 1/1;
    }
  }

  .text {
    font-size: 18px;
    font-weight: 600;
    margin: 0px 0px 0px 2%;

    @include lt-md {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.checked-color-main {
  background-color: var(--main);

  .text {
    color: white;
  }

  .centerItem {
    background-color: var(--main);
  }
}

.checked-color-green {
  background-color: var(--green);

  .text {
    color: white;
  }

  .centerItem {
    background-color: var(--green);
  }
}
