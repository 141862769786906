@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  width: 100%;
}

.header {
  width: 100%;
  aspect-ratio: 10/1.2;
  position: relative;
  border-bottom: 7px solid #000;

  @include lt-md {
    aspect-ratio: 10/4;
  }

  .cover {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .filter {
    position: absolute;
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.2);
    top: 0px;
    right: 0px;
    z-index: 2;
  }

  .content {
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0px;
    z-index: 2;
    padding: 50px 10% 0px 10%;

    @include lt-md {
      padding: 20px 6% 0px 6%;
    }
  }
}

.header2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10% 0px 10%;

  transform: translateY(-40%);

  @include lt-md {
    flex-direction: column;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @include lt-md {
      flex-direction: column;
    }

    .avatar_img {
      border: 7px solid #fff;
      background-color: #000;
      border-radius: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transition: all 0.2s;

      &:hover {
        transform: scale(1.05);
      }

      img {
        width: 230px;
        aspect-ratio: 1/1;
        border-radius: 500px;
        object-fit: cover;
        object-position: 50% 50%;

        @include lt-xxl {
          width: 200px;
        }

        @include lt-md {
          width: 170px;
        }
      }
    }

    .name {
      margin: 50px 0px 0px 2%;
      h3 {
        color: #000;
        font-size: 35px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;
      }

      span {
        display: block;
        margin: 2% 0px 0px 0px;
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2;
      }

      @include lt-md {
        margin: 20px 0px 0px 0px;
        text-align: center;
      }
    }
  }
  .btn {
    width: fit-content !important;
    height: fit-content !important;
    padding: 20px 40px;
    border-radius: 15px;
  }
}

.switcher {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 10px 20px;
  margin: 0px auto 20px auto;
  border-bottom: solid 2px #000;

  @include lt-md {
    width: 95%;
    padding: 0px 0px 10px 10px;
  }

  .switcher_item {
    margin: 0px 30px 0px 0px;
    display: flex;
    align-items: center;

    @include lt-md {
      margin: 0px 0px 0px 0px;
      align-items: center;
      justify-content: space-between;
    }

    span {
      font-size: 20px;
      font-weight: 700;
      color: #000;
      margin: 0px 20px 0px 0px;
      cursor: pointer;
      transition: all 0.2s;

      @include lt-md {
        font-size: 15px;
        margin: 0px 10px 0px 0px;
      }
    }

    .icon {
      font-size: 20px;
      color: #000;
      transition: all 0.2s;
      transform: translateY(2px);
    }

    &:hover {
      span {
        color: var(--main);
      }
      .icon {
        color: var(--main);
      }
    }
  }

  .selected {
    span {
      color: var(--main) !important;
      // border-bottom: solid 5px var(--main);
    }
  }
}

.body {
  width: 80%;
  margin: 3% auto 3% auto;

  @include lt-md {
    width: 100%;
    padding: 0px 6%;
  }
}

.trainers {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5%;
  width: 100%;

  .trainer {
    width: 24%;
    margin: 0px 0.5% 2% 0.5%;

    @include lt-xl {
      width: 31%;
      margin: 0px 1.16% 2% 1.16%;
    }

    @include lt-lg {
      width: 48%;
      margin: 0px 1% 2% 1%;
    }

    @include lt-md {
      width: 100%;
    }
  }
}

.blogs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
