@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  background-color: #000;
  width: 100%;
  padding: 50px 10% 50px 10%;

  color: white;

  @include lt-xxl {
    padding: 40px 6% 40px 6%;
  }
}

.footer_sections {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  @include lt-md {
    flex-direction: column;
  }
}

.main_section {
  width: 30%;

  @include lt-md {
    width: 100%;
  }

  h1 {
    font-size: 45px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10%;

    @include lt-xxl {
      font-size: 35px;
    }

    @include lt-md {
      font-size: 40px;
      br {
        display: none;
      }
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;

    @include lt-xxl {
      font-size: 18px;
    }

    @include lt-md {
      font-size: 20px;
    }
  }

  .rxs {
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include lt-md {
      margin-top: 2%;
      margin-bottom: 0%;
    }

    img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      margin: 0px 20px 0px 0px;
      cursor: pointer;
      transition: all 0.2s;

      @include lt-xxl {
        width: 45px;
        height: 45px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.section {
  width: 20%;

  @include lt-md {
    width: 100%;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 5%;

    @include lt-xxl {
      font-size: 18px;
    }

    @include lt-md {
      font-size: 20px;
      margin-bottom: 0%;
      margin-top: 5%;
    }
  }

  a {
    display: block;
    font-size: 17px;
    font-weight: 300;
    line-height: 2;
    margin-top: 3%;
    color: white;
    text-decoration: none;

    @include lt-xxl {
      font-size: 15px;
    }

    @include lt-md {
      font-size: 18px;
      margin-top: 1%;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: 17px;
    font-weight: 300;
    line-height: 2;
    margin-top: 3%;

    @include lt-xxl {
      font-size: 15px;
    }
  }

  strong {
    display: block;
    margin-top: 9%;
    font-size: 17px;
    font-weight: 600;

    @include lt-xxl {
      font-size: 15px;
    }
  }

  span {
    font-size: 14px;
    font-weight: 200;
    line-height: 1;
    margin-top: 2%;

    @include lt-xxl {
      font-size: 13px;
    }
  }
}

.copy_rights {
  margin-top: 2%;

  .trenaout {
    color: #fff;

    font-size: 20px;
    font-weight: 400;

    @include lt-xxl {
      font-size: 16px;
    }
  }

  .cp_txt {
    color: #96999f;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.22px;

    @include lt-xxl {
      font-size: 16px;
    }
  }
}

.newsletter {
  margin-top: 10%;
  width: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include lt-md {
    margin-top: 5%;
  }

  input {
    width: 90%;
    padding: 16px 45px 16px 14px;

    // background-color: rgba(255, 255, 255, 0.07);
    background-color: rgb(198, 198, 198);

    outline: none;
    border: none;
    border-radius: 15px 0px 0px 15px;

    // color: white;
    color: black;
    font-size: 14px;
    font-weight: 600;

    // placeholder
    &::placeholder {
      color: rgb(76, 76, 76);
    }

    @include lt-xxl {
      font-size: 13px;
      padding: 14px 40px 14px 12px;
    }
  }

  img {
    height: 110%;
    position: absolute;
    right: 0px;
    cursor: pointer;

    transition: all 0.2s;

    @include lt-xxl {
      height: 110%;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}
