@use "../../../Assets/Scss/media-queries.scss" as *;

.main {
  background-color: #f8f8f8;
  width: 30vw;
  border-radius: 10px;
  padding: 6% 6%;
  position: relative;

  flex-direction: column;

  @include lt-xxl {
    width: 30vw;
  }

  @include lt-lg {
    width: 50vw;
  }

  @include lt-md {
    width: 92vw;
  }
}
