@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  padding: 8% 10% 100px 10%;

  @include lt-xxl {
    padding: 12% 8% 100px 8%;
  }

  @include lt-md {
    padding: 100px 5%;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 3%;
  background-color: #e7e7e7;
  border-radius: 10px;

  @include lt-md {
    padding: 15px 20px;
  }

  .GoBack {
    img {
      height: 20px;

      @include lt-md {
        height: 14px;
      }
    }
  }
}

.blog {
  width: 100%;
  margin: 2% 0px;

  h1 {
    color: #000;
    margin: 0px 0px 2% 0px;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.7;

    .icon {
      height: 40px;
      width: 40px;
    }

    @include lt-md {
      font-size: 26px;
      line-height: 1.4;
      margin: 6% 0px 4% 0px;

      .icon {
        height: 26px;
        width: 26px;
      }
    }
  }

  .content {
    width: 100%;

    img {
      width: 70%;
      display: block;
      margin: 2% auto;

      @include lt-md {
        width: 100%;
      }
    }

    h1 {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.7;

      @include lt-md {
        font-size: 26px;
        line-height: 1.4;
      }
    }

    h2 {
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.7;

      @include lt-md {
        font-size: 24px;
        line-height: 1.4;
      }
    }

    h3 {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.7;
      margin: 20px 0px 0px 0px;

      @include lt-md {
        font-size: 22px;
        line-height: 1.4;
      }
    }
    h4 {
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.7;

      @include lt-md {
        font-size: 20px;
        line-height: 1.4;
      }
    }

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 2;
      text-align: justify;
      margin: 0.5% 0px;

      @include lt-md {
        font-size: 18px;
        text-align: justify;
      }
    }
  }
}
