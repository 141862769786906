@use "../../../Assets/Scss/media-queries.scss" as *;

.container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.eye {
  position: absolute;
  cursor: pointer;
  top: 0px;
  height: calc(100% - 10px);
  aspect-ratio: 1;
  color: gray;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 23px;
  background-color: white;
  border-radius: 0px 12px 12px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 1px solid rgba(0, 0, 0, 0.13);
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);

  @include lt-xxl {
    font-size: 20px;
  }
}

.invalid {
  border-color: var(--main) !important;
  color: var(--main) !important;
}
