@use "../../Assets/Scss/media-queries.scss" as *;

$slider-height: 100px;
$item-width: 600px;
$nb-items-to-show: 6;
$nb-items: $nb-items-to-show * 2;

// Override on small screens
@media (max-width: 768px) {
  $slider-height: 80px;
  $item-width: 200px;
}

.slider {
  height: $slider-height;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track {
  display: flex;
  width: calc($item-width * $nb-items);
  animation: scroll 30s linear infinite;
}

.slide-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-$item-width * $nb-items-to-show);
  }
}

.slide {
  height: $slider-height;
  width: $item-width;
  display: flex;
  align-items: center;
  padding: 15px 50px;
}

.slide img {
  width: 100%;
  transition: all 0.3s;
}

.slide img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.slider::before,
.slider::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.slider::before {
  left: 0;
  top: 0;
  background-image: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
}

.slider::after {
  right: 0;
  top: 0;
  background-image: linear-gradient(270deg, #fff 0, hsla(0, 0%, 100%, 0));
}
