@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  width: 100%;
  height: 100vh;
}

.img_container {
  width: 60%;
  height: 100%;

  @include lt-md {
    width: 0%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 30%;
    display: block;
  }
}

.content {
  width: 40%;
  height: 100%;
  background-color: #f8f8f8;
  padding: 0% 6% 0 6%;

  @include lt-xxl {
    padding: 0% 6% 0 6% !important;
    width: 100%;
  }

  .container {
    width: 100%;
  }
}

.header {
  .logo {
    width: 120px;
    display: block;
    margin: 0px auto 1% auto;

    @include lt-xxl {
      width: 100px;
      margin: 0px auto 0% auto;
    }
  }

  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin: 0px 0px 1% 0px;

    @include lt-xxl {
      font-size: 25px;
      margin: 0px 0px 0% 0px;
    }
  }

  p {
    text-align: center;
    font-size: 18px;
    line-height: 1.6;
    max-width: 100%;
    margin: auto;
    font-weight: 500;

    @include lt-xxl {
      font-size: 14px;
    }
  }
}

.rxs {
  margin: 3% 0px 0px 0px;

  @include lt-xxl {
    margin: 2% 0px 0px 0px;
  }

  @include lt-md {
    flex-direction: column;
  }

  button {
    outline: none;
    border-radius: 9.073px;
    border: 0.827px solid #e1e1e1;
    background: rgba(255, 255, 255, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    // width: 49%;
    width: 100%;
    padding: 12px 0px;

    @include lt-xxl {
      padding: 9px 0px;
    }

    @include lt-md {
      width: 100%;
      margin: 10px 0px 0px 0px;
      padding: 12px 0px;
    }

    cursor: pointer;

    img {
      width: 24px;
      aspect-ratio: 1/1;
      transition: all 0.2s;
      margin: 0px 7px 0px 0px;

      @include lt-xxl {
        width: 20px;
      }
    }

    span {
      font-size: 15px;
      font-weight: 500;
      color: #666;

      @include lt-xxl {
        font-size: 14px;
      }
    }
  }
}

.separ {
  height: 1px;
  width: 100%;
  background-color: #d6d6d6;
  align-items: center;
  justify-content: center;

  margin: 20px 0px;

  @include lt-xxl {
    margin: 15px 0px;
  }

  span {
    color: #a7a7a7;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1; /* 19.847px */
    background-color: #f8f8f8;
    transform: translateY(-6px);
    display: block;
    padding: 0px 10px;

    @include lt-xxl {
      font-size: 14px !important;
    }
  }
}

.form {
  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0px 10px 0px;

    @include lt-xxl {
      margin: 0px 0px 10px 0px;
    }

    .w-100 {
      min-width: 100%;
      max-width: 100%;
    }

    .w-75 {
      min-width: 74%;
      max-width: 74%;
    }

    .w-50 {
      min-width: 49%;
      max-width: 49%;

      @include lt-md {
        min-width: 100%;
        max-width: 100%;
      }
    }

    .w-50-keep {
      min-width: 49%;
      max-width: 49%;
    }

    .w-30 {
      min-width: 32%;
      max-width: 32%;

      @include lt-md {
        min-width: 100%;
        max-width: 100%;
      }
    }

    .w-25 {
      min-width: 24%;
      max-width: 24%;
    }

    input,
    select {
      border-radius: 12px;
      margin: 0px 0px 10px 0px;
      border: 1px solid rgba(0, 0, 0, 0.13);
      // background: rgba(255, 255, 255, 0.5);
      background: rgba(255, 255, 255, 1);

      font-size: 16px;
      font-weight: 400;

      padding: 18px 20px;
      outline: none;

      @include lt-xxl {
        padding: 14px 20px;
        font-size: 14px;
      }
    }
  }
}

.btn_cont {
  width: 100%;
  margin: 15px 0px 0px 0px;

  @include lt-xxl {
    margin: 10px 0px 0px 0px;
  }

  button {
    width: 100%;
    height: fit-content;
    padding: 12px 0px;
    font-size: 16px;

    @include lt-xxl {
      padding: 12px 0px;
      font-size: 14px;
    }

    &:hover {
      transform: scale(1);
    }
  }
}

.footer {
  margin: 0px 0px 0% 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include lt-xxl {
    margin: 0px 0px 0% 0px;
  }

  span {
    color: #666;
    font-size: 15px;
    text-align: center;
    cursor: pointer;

    @include lt-xxl {
      font-size: 15px;
    }
  }

  .remember {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    height: 16px;
    width: 16px;
    margin-right: 3px;
    accent-color: var(--main);
  }
}

.otherOptin {
  color: #666;
  font-size: 17px;
  text-align: center;
  margin-top: 10px;

  @include lt-xxl {
    font-size: 15px;
  }

  .a {
    color: var(--main);
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
