@use "../../Assets/Scss/media-queries.scss" as *;

.main {
  padding: 100px 10%;

  @include lt-xxl {
    padding: 50px 8%;
  }

  @include lt-md {
    padding: 20px 6%;
  }
}

.h1 {
  text-align: center;
  margin: 0px 0px 100px 0px;

  @include lt-md {
    margin: 0px 0px 20px 0px;
  }
}

.section_text_img {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 0px 0px 5% 0px;

  @include lt-md {
    flex-direction: column;
  }

  .part_content {
    width: 48%;
    position: relative;

    @include lt-md {
      width: 100%;
    }

    p {
      color: #000;

      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.7;
      margin: 0px 0px 4% 0px;
      text-align: justify;

      @include lt-xxl {
        font-size: 18px;
      }

      @include lt-md {
        margin: 0px 0px 0% 0px;
        font-size: 17px;
        br {
          display: none;
        }
      }

      strong {
        font-weight: 700;
      }
    }

    button {
      position: absolute;
      bottom: 20px;

      @include lt-xxl {
        font-size: 16px !important;
        height: 60px !important;
        bottom: 0px;
      }

      @include lt-md {
        display: none;
      }
    }
  }

  .part_image {
    width: 48%;

    @include lt-md {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 20px;

      @include lt-md {
        border-radius: 10px;
        margin-top: 20px;
      }
    }
  }

  .part_button {
    display: none;
    margin: 20px auto 0px auto;

    @include lt-md {
      display: block;
    }
  }
}

.section_reversed {
  flex-direction: row-reverse;

  @include lt-md {
    flex-direction: column;
  }
}

.services {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;

  margin: 10% 0px;

  @include lt-xxl {
    margin: 8% 0px;
  }

  @include lt-md {
    margin: 6% 0px;
    flex-direction: column;
  }

  .service {
    width: 32%;
    margin: 0.6% 0.6%;

    background-color: #e4e4e4;
    border-radius: 20px;
    padding: 25px;
    transition: all 0.2s;
    cursor: default;

    @include lt-xxl {
      border-radius: 10px;
      padding: 20px;
    }

    @include lt-md {
      width: 100%;
      margin: 5px 0px;
    }

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }

    .img_cont {
      width: 50px;
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      transform: scale(1.4) translateX(8px);

      @include lt-xxl {
        width: 40px;
        height: 40px;
        transform: scale(1.1) translateX(8px);
      }

      @include lt-md {
        margin: auto;
      }
    }

    h3 {
      color: #000;

      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.7;

      @include lt-xxl {
        font-size: 20px;
      }

      @include lt-md {
        text-align: center;
      }
    }

    p {
      color: #000;

      font-size: 18px;
      font-weight: 400;
      line-height: 1.7;

      @include lt-xxl {
        font-size: 14px;
        font-weight: 500;
      }

      @include lt-xxl {
        text-align: justify;
        text-align-last: center;
      }
    }
  }
}
